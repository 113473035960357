import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';

import IngredientDropDown from './IngredientsDropDown';
import styles from './IngredientsSelect.module.css';

const IngredientsSelect = (props) => {
  const { 
    initialIngredients, 
    ingredientsData,
    onChange,
   } = props;

  const [currentAlternative, setCurrentAlternative] = useState(null);
  const [selectedAlternativeList, setSelectedAlternativeList] = useState(new Map());
  
  useEffect(() => {
    setCurrentAlternative(null);

    if (initialIngredients && ingredientsData?.length) {
      if (initialIngredients.length) {
        let selectedAlternativeList = new Map();
        initialIngredients.forEach(ingredient => {

          const ingredientId = ingredient?.id || ingredient;

          const ingredientData = ingredientsData.find(ingredient => ingredient.id == ingredientId);
          
          selectedAlternativeList.set(+ingredientId, ingredientData);
        });
        setSelectedAlternativeList(selectedAlternativeList);
      } else {
        const newInitialIngredients = new Map(initialIngredients);
        setSelectedAlternativeList(newInitialIngredients);
      }
    } 
  }, [initialIngredients, ingredientsData]);

  const changeAlternativeHandler = (event) => {
    const { value } = event.target;
    const selectedIngredient = ingredientsData.find(ingredient => ingredient.id == value);
    setCurrentAlternative(selectedIngredient);
  };

  const changeAlternativeList = (alternativeList) => {
    setSelectedAlternativeList(alternativeList);
    onChange(alternativeList);
  };

  const addAlternativeHandler = () => {
    if (!currentAlternative) {
      return toast.error('Select ingredient', {
        position: 'bottom-right',
        duration: 3000,
      });
    }

    const alternativeData = ingredientsData.find(ingredient => ingredient.id === currentAlternative.id);

    selectedAlternativeList.set(currentAlternative.id, alternativeData);

    changeAlternativeList(new Map(selectedAlternativeList));
    setCurrentAlternative('');
  };

  const deleteAlternativeHandler = (id) => {
    selectedAlternativeList.delete(id);
    changeAlternativeList(new Map(selectedAlternativeList));
  };

  return (
    <>
      <div className={styles.alternativeBlock}>
        <IngredientDropDown 
          value={currentAlternative?.id || ''}
          onChange={changeAlternativeHandler}
          ingredientsData={ingredientsData}
        />

        <div className={styles.addAlternative} onClick={addAlternativeHandler}>
          +
        </div>
      </div>
      <div className={styles.alternativeList}>
        {Array.from(selectedAlternativeList.entries()).map(([id, ingredient], index) => (
          <div className={styles.selectedAlternative} key={`${id}.${index}`}>
            {ingredient?.name}
            <div className={styles.delete} onClick={() => deleteAlternativeHandler(id)}>
              ×
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default IngredientsSelect;