const siteURL = 'http://localhost:3000';
const backURL = 'http://localhost:3002/';
const backURLApi = `${backURL}api/v1/`;

const config = {
  siteURL,
  backURL,
  backURLApi,
};

export default config;




