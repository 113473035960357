import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import DishForm from '../../components/DishForm/DishForm';

import { getDishById, clearCurrentDish, updateDish } from '../../actions/dish';

const EditDish = (props) => {
  const { 
    getDishById,
    clearCurrentDish,
    updateDish, 
    dishData 
  } = props;

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    getDishById(params.id);
    return () => clearCurrentDish();
  }, []);

  const updateDishHandler = (newDishData) => {
    newDishData.id = dishData.id;
    updateDish(newDishData, () => {
      navigate('/dishes');
    });
  };

  return (
    <div className="wrapper">
      <h1>Add Dish</h1>
      {dishData && (
        <DishForm 
          onSave={updateDishHandler} 
          initialValues={dishData} 
        />
      )}
    </div>
  );
};

const mapStateToProps = (store) => ({
  dishData: store.dish.currentDish,
});

const mapDispatchToProps = {
  getDishById,
  clearCurrentDish,
  updateDish,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDish);