import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import cn from 'classnames';
import _ from 'lodash';

import { getIngredients } from '../../actions/ingredient';
import DishComponent from './DishComponent';
import DishImage from './DishImage';
import DishVideo from './DishVideo';
import styles from './DishForm.module.css';

const defaultComponent = {
  fakeId: Date.now(),
  name: '',
  title: '',
  instruction: '',
  ingredients: [],
};

const DishForm = (props) => {
  const {
    getIngredients, 
    ingredientsData, 
    initialValues = {}, 
    onSave,
  } = props;

  const [name, setName] = useState(initialValues?.name || '');
  const [mealName, setMealName] = useState(initialValues?.meal_name || '');
  const [cookingTime, setCookingTime] = useState(initialValues?.cooking_time || '');
  const [glutenFree, setGlutenFree] = useState(initialValues?.gluten_free || false);
  const [difficulty, setDifficulty] = useState(initialValues?.difficulty || '');
  const [serving, setServing] = useState(initialValues?.serving || '');
  const [components, setComponents] = useState(initialValues?.components || [defaultComponent]);
  const [image, setImage] = useState(initialValues?.image);
  const [video, setVideo] = useState(initialValues?.video);

  useEffect(() => {
    getIngredients();
  }, []);

  const onSaveHandler = () => {
    const trimmedName = name.trim();
    if (!trimmedName) {
      return toast.error('Name is required', {
        position: 'bottom-right',
        duration: 3000,
      });
    }

    if (!mealName) {
      return toast.error('Meal name is required', {
        position: 'bottom-right',
        duration: 3000,
      });
    }
    
    if (!cookingTime) {
      return toast.error('Cooking time is required', {
        position: 'bottom-right',
        duration: 3000,
      });
    }
    
    if (!difficulty) {
      return toast.error('Difficulty is required', {
        position: 'bottom-right',
        duration: 3000,
      });
    }
    
    if (!image) {
      return toast.error('Pleas select dish image', {
        position: 'bottom-right',
        duration: 3000,
      });
    }

      // Check all data in components
    for (let component of components) {
      const {
        name,
        title,
        instruction,
        ingredients,
      } = component;

      if (!name || !title || !instruction || _.isEmpty(ingredients)) {
        return toast.error('All fields in component is required', {
          position: 'bottom-right',
          duration: 3000,
        });
      }

      if (!ingredients?.length) {
        const ingredientsArray = [];
        ingredients.forEach((data) => {
          ingredientsArray.push(data);
        });

        component.ingredients = ingredientsArray;
      } 
    }

    const dishData = {
      name: trimmedName,
      meal_name: mealName,
      image,
      video,
      cooking_time: cookingTime,
      difficulty,
      gluten_free: glutenFree,
      components,
      serving,
    };

    onSave(dishData);
  };

  const onChangeComponentHandler = (componentData, index) => {
    Object.assign(components[index], componentData);
    // components[index] = componentData;
    setComponents([...components]);
  };

  const addComponent = () => {
    const newComponent = {
      ...defaultComponent,
      fakeId: Date.now(),
    };

    components.push(newComponent);
    setComponents([...components]);
  };

  const deleteComponent = (index) => {
    components.splice(index, 1);
    setComponents([...components]);
  };

  const valueChangeHandler = (setter) => (event) => {
    setter(event.target.value);
  };

  const changeImageHandler = (imageData) => {
    setImage(imageData);
  };

  const changeVideoHandler = (videoData) => {
    setVideo(videoData);
  };

  return (
    <div>
      <label className={styles.row}>
        <span>Name of dish</span>
        <input 
          className={styles.input}
          placeholder="Name of dish"
          value={name}
          onChange={valueChangeHandler(setName)}
        />
      </label>
      <label className={styles.row}>
        <span>Meal name</span>
        <select 
          className={styles.select}
          onChange={valueChangeHandler(setMealName)}
          value={mealName}
        >
          <option value="" disabled>Select meal name</option>
          <option value="breakfast">Breakfast</option>
          <option value="snack">Snack</option>
          <option value="lunch">Lunch</option>
          <option value="dinner">Dinner</option>
        </select>
      </label>
      <label className={styles.row}>
        <span>Cooking time (min)</span>
        <input 
          className={styles.input}
          placeholder="Cooking time (min)"
          value={cookingTime}
          type="number"
          onChange={valueChangeHandler(setCookingTime)}
        />
      </label>

      <label className={styles.row}>
        <span>Difficulty in preparing the dish</span>
        <select 
          className={styles.select}
          onChange={valueChangeHandler(setDifficulty)}
          value={difficulty}
        >
          <option value="" disabled>Select difficulty</option>
          <option value="easy">Easy</option>
          <option value="medium">Medium</option>
          <option value="hard">Hard</option>
        </select>
      </label>
      <label className={styles.checkbox}>
        <span>Gluten free</span>
        <input  
          checked={glutenFree}
          onChange={({ target }) => setGlutenFree(target.checked)}
          type="checkbox"
        />
      </label>

      <div className={styles.dishImages}>
        <div className={styles.dishImagesTitle}>
          Dish video
        </div>
        <DishVideo
          initialValues={video}
          onChange={changeVideoHandler}
        />
      </div>
      <div className={styles.dishImages}>
        <div className={styles.dishImagesTitle}>
          Dish image
        </div>
        <DishImage
          initialValues={image}
          onChange={changeImageHandler}
        />
      </div>
      <div className={styles.dishComponentsWrapper}>
        <div className={styles.dishComponents}>Dish Components</div>
        {components.map((component, index) => (
          <div className={styles.componentBlock} key={component.fakeId || component.id} >
            <DishComponent 
              initialValues={component}
              onChange={componentData => onChangeComponentHandler(componentData, index)}
              ingredientsData={ingredientsData}
            />
            {components.length > 1 && (
              <button 
                className={cn(styles.saveButton, 'button', 'red-button')} 
                onClick={() => deleteComponent(index)}
              >
                Delete component
              </button>
            )}
          </div>
        ))}
        <button 
          className={cn(styles.saveButton, 'button', 'green-button')} 
          onClick={addComponent}
        >
          Add component
        </button>
      </div>
      <label className={styles.row}>
        <span>Serving</span>
        <textarea 
          className={cn(styles.input, styles.serving)}
          placeholder="Serving"
          value={serving}
          onChange={valueChangeHandler(setServing)}
        />
      </label>

      <button 
        className={cn(styles.saveButton, 'button', 'blue-button')} 
        onClick={onSaveHandler}
      >
        Save
      </button>
    </div>
  );
};

const mapStateToProps = (store) => ({
  ingredientsData: store.ingredient.ingredientsData,
});

const mapDispatchToProps = {
  getIngredients,
};

export default connect(mapStateToProps, mapDispatchToProps)(DishForm);
