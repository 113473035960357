import { allergiesAPI } from '../api/allergy';
import { actionWrapper } from './actionWrapper';

export const SET_ALLERGIES = 'SET_ALLERGIES';
export const DELETE_ALLERGY = 'DELETE_ALLERGY';

export const getAllergies = () => actionWrapper(async () => {
  const allergiesData = await allergiesAPI.getAllergies();

  return {
    type: SET_ALLERGIES,
    payload: allergiesData,
  };
});

export const addAllergy = (allergyData) => actionWrapper(async (dispatch) => {
  await allergiesAPI.addAllergy(allergyData);
  dispatch(getAllergies());
});

export const updateAllergy = (allergyData) => actionWrapper(async (dispatch) => {
  await allergiesAPI.updateAllergy(allergyData);
  dispatch(getAllergies());
});

export const deleteAllergy = (id) => actionWrapper(async () => {
  await allergiesAPI.deleteAllergy(id);

  return {
    type: DELETE_ALLERGY,
    payload: id,
  };
});