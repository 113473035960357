import React, { useState } from 'react';
import { connect } from 'react-redux';
import { DndContext, closestCenter } from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
} from '@dnd-kit/sortable';

import { setDishPriorities } from '../../actions/dish';
import { SortableItem } from './SortableItem';

import styles from './DishPriorities.module.css';

const MealPriorities = (props) => {
  const { mealDishes, meal, setDishPriorities } = props;
  const [dishes, setDishes] = useState(mealDishes);

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      let newList;
      setDishes((dishes) => {
        const oldIndex = dishes.findIndex(dish => dish.id === active.id);
        const newIndex = dishes.findIndex(dish => dish.id === over.id);
        newList = arrayMove(dishes, oldIndex, newIndex);
        return newList;
      });

      setDishPriorities(newList);
    }
  };

  return (
    <div className={styles.mealBlock}>
      <div className={styles.mealName}>{meal}</div>

      <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={dishes} strategy={rectSortingStrategy}>
          {dishes.map((dish) => (
            <SortableItem key={dish.id} dish={dish} />
          ))}
        </SortableContext>
      </DndContext>
    </div>
  );
};

const mapDispatchToProps = {
  setDishPriorities,
};

export default connect(null, mapDispatchToProps)(MealPriorities);