import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';

import { unitTypeLabels, UNIT_TYPE_WITHOUT_AMOUNT } from '../../constants/unitTypes';
import { getIngredients, deleteIngredient, calculateAllNutrients } from '../../actions/ingredient';
import IngredientModal from '../../components/IngredientModal/IngredientModal';
import styles from './Ingredients.module.css';

const Ingredients = (props) => {
  const { 
    getIngredients, 
    ingredientsData,
    deleteIngredient,
    calculateAllNutrients,
   } = props;

  const [showModal, setShowModal] = useState(false);
  const [initialValue, setInitialValue] = useState(null);

  useEffect(() => {
    getIngredients();
  }, []);

  const openModalHandler = () => {
    setInitialValue(null);
    setShowModal(true);
  };

  const editHandler = (ingredientData) => {
    setInitialValue(ingredientData);
    setShowModal(true);
  };

  const deleteHandler = (id) => {
    const confirmResult = window.confirm('Are you sure?');

    if (confirmResult) {
      deleteIngredient(id);
    }
  };

  const calculateNutrientsHandler = () => {
    const confirmResult = window.confirm('Are you sure?');

    if (confirmResult) {
      calculateAllNutrients();
    }
  };

  const renderNutrients = (ingredient) => {
    if (ingredient.unit_type === UNIT_TYPE_WITHOUT_AMOUNT) {
      return 'Not relevant';
    }

    return (
      <div key={ingredient.id}>
        {ingredient.calories !== null && (
          <div>Calories: {ingredient.calories} kcal</div>
        )}
        {ingredient.fat !== null && (
          <div>Fat: {ingredient.fat} grams</div>
        )}
        {ingredient.protein !== null && (
          <div>Protein: {ingredient.protein} grams</div>
        )}
        {ingredient.carbohydrates !== null && (
          <div>Carbohydrates: {ingredient.carbohydrates} grams</div>
        )}
      </div>
    );
  };

  return (
    <div className="wrapper">
      <IngredientModal 
        key={initialValue?.id}
        isOpen={showModal} 
        onClose={() => setShowModal(false)}
        initialValue={initialValue}
      />
      <div className={styles.tools}>
      <h1>
        Ingredients
      </h1>
      <div className={styles.buttons}>
        <div className={styles.calculateNutrients} onClick={calculateNutrientsHandler}>
          Calculate nutrients
        </div>
        <div className={styles.addDish} onClick={openModalHandler}>
          Add ingredient
        </div>
      </div>
      </div>
      <table className={cn(styles.table, 'table')}>
        <thead>
        <tr>
          <th>Name</th>
          <th>Unit type</th>
          <th>Nutrients</th>
          <th>Alternative Ingredients</th>
          <th>Controls</th>
        </tr>
        </thead>
        <tbody>
        {ingredientsData?.map((ingredientItem) => (
          <tr key={ingredientItem.id}>
            <td>
              {ingredientItem.name}
            </td>
            <td>{unitTypeLabels[ingredientItem.unit_type]}</td>
            <td>{renderNutrients(ingredientItem)}</td>
            <td>
              {ingredientItem.alternatives?.map((alternative) => (
                <div key={alternative.id}>
                  {alternative.name}
                </div>
              ))}
            </td>
            <td className="controls">
              <button className="button green-button" onClick={() => editHandler(ingredientItem)}>
                Edit
              </button>
              <button className="button red-button" onClick={() => deleteHandler(ingredientItem.id)}>
                Delete
              </button>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (store) => ({
  ingredientsData: store.ingredient.ingredientsData,
});

const mapDispatchToProps = {
  getIngredients,
  deleteIngredient,
  calculateAllNutrients,
};

export default connect(mapStateToProps, mapDispatchToProps)(Ingredients);