import { get, post, put, deleteById } from './api';

export const allergiesAPI = {
  getAllergies() {
    return get('admin/allergy');
  },

  addAllergy(allergyData) {
    return post('admin/allergy', allergyData);
  },

  updateAllergy(allergyData) {
    return put('admin/allergy', allergyData);
  },

  deleteAllergy(id) {
    return deleteById('admin/allergy', id);
  },
};
