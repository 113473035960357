import {
  SET_MOBILE_USERS,
  SET_MOBILE_USER_DETAILS,
  SET_MOBILE_CHAT_GPT_DETAILS,
  SET_CHAT_GPT_TESTS,
  SET_CHAT_GPT_TEST_DETAILS,
} from '../actions/mobileUser';

let initialState = {
  mobileUsersData: [],
  mobileUserDetails: null,
  chatGPTDetails: null,
  chatGPTTests: null,
  chatGPTTestDetails: null,
};

const mobileUser = (state = initialState, action) => {
  switch (action.type) {
    case SET_MOBILE_USERS:
      return {
        ...state,
        mobileUsersData: action.payload,
      };

    case SET_MOBILE_USER_DETAILS:
      return {
        ...state,
        mobileUserDetails: action.payload,
      };

    case SET_MOBILE_CHAT_GPT_DETAILS:
      return {
        ...state,
        chatGPTDetails: action.payload,
      };

    case SET_CHAT_GPT_TESTS:
      return {
        ...state,
        chatGPTTests: action.payload,
      };

    case SET_CHAT_GPT_TEST_DETAILS:
      return {
        ...state,
        chatGPTTestDetails: action.payload,
      };

    default:
      return state;
  }
};

export default mobileUser;
