import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';

import { addIngredient, updateIngredient, calculateNutrients } from '../../actions/ingredient';
import { unitTypes, UNIT_TYPE_WITHOUT_AMOUNT } from '../../constants/unitTypes';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import AlternativesSelect from '../IngredientsSelect/AlternativesSelect';
import styles from './IngredientModal.module.css';

const IngredientModal = (props) => {
  const { 
    initialValue, 
    isOpen, 
    onClose,
    addIngredient,
    updateIngredient,
    ingredientsData,
    calculateNutrients,
    ingredientNutrients,
  } = props;

  console.log('===ingredientNutrients', ingredientNutrients);

  const [name, setName] = useState('');  
  const [unitType, setUnitType] = useState('');
  const [calories, setCalories] = useState('');
  const [fat, setFat] = useState('');
  const [protein, setProtein] = useState('');
  const [carbohydrates, setCarbohydrates] = useState('');
  const [selectedAlternativeList, setSelectedAlternativeList] = useState([]);
  const [alternativeList, setAlternativeList] = useState([]);

  console.log('===initialValue', initialValue);

  useEffect(() => {
    if (initialValue) {
      setName(initialValue?.name);
      setUnitType(initialValue?.unit_type);
      setCalories(initialValue?.calories);
      setFat(initialValue?.fat);
      setProtein(initialValue?.protein);
      setCarbohydrates(initialValue?.carbohydrates);

      const alternativeList = ingredientsData.filter(ingredient => ingredient.id !== initialValue.id);
      setAlternativeList(alternativeList);

      if (initialValue.alternatives) {
        setSelectedAlternativeList(initialValue.alternatives);
      }

    } else {
      setAlternativeList(ingredientsData);
    }
  }, [isOpen, initialValue, ingredientsData]);

  useEffect(() => {
    if (ingredientNutrients) {
      setCalories(ingredientNutrients.calories);
      setFat(ingredientNutrients.fat);
      setProtein(ingredientNutrients.protein);
      setCarbohydrates(ingredientNutrients.carbohydrates);
    }

  }, [ingredientNutrients]);

  const title = initialValue ? 'Edit ingredient' : 'Add new ingredient';

  const changeValueHandler = setter => event => {
    setter(event.target.value);
  };

  const onSaveHandler = () => {
    const trimmedName = name.trim();
    if (!trimmedName) {
      return toast.error('Name is required', {
        position: 'bottom-right',
        duration: 3000,
      });
    }

    if (!unitType) {
      return toast.error('Unit type is required', {
        position: 'bottom-right',
        duration: 3000,
      }); 
    }

    // Check if there is an ingredient with the same name and unit type
    const existenceIngredient = ingredientsData.find(ingredient => {
      if (ingredient.name !== trimmedName) {
        return false;
      }

      return ingredient.unit_type === unitType && initialValue?.id !== ingredient.id;
    });

    if (existenceIngredient) {
      return toast.error('Ingredient with this name and unit type already exists', {
        position: 'bottom-right',
        duration: 3000,
      }); 
    }

    const alternatives = selectedAlternativeList.length ? selectedAlternativeList : Array.from(selectedAlternativeList.keys());

    const ingredientData = {
      name: trimmedName,
      unit_type: unitType,
      alternatives,
    };

    if (calories) {
      ingredientData.calories = calories;
    }

    if (fat) {
      ingredientData.fat = fat;
    }

    if (protein) {
      ingredientData.protein = protein;
    }

    if (carbohydrates) {
      ingredientData.carbohydrates = carbohydrates;
    }

    if (initialValue) {
      ingredientData.id = initialValue.id;
      updateIngredient(ingredientData);
    } else {
      addIngredient(ingredientData);
    }

    onClose();
  };

  const calculateNutrientsHandler = () => {
    const ingredientData = {
      name,
      unit_type: unitType,
    };
    calculateNutrients(ingredientData);
  };

  return (
    <ModalWrapper 
      isOpen={isOpen} 
      onClose={onClose} 
      onSave={onSaveHandler} title={title}
    >
      <div className={styles.wrapper}>
        <label className={styles.row}>
          <span>Ingredient name</span>
          <input 
            className={styles.input} 
            onChange={changeValueHandler(setName)}
            placeholder="Ingredient name"
            value={name}
          />
        </label>
        <label className={styles.row}>
          <span>Unit type</span>
          <select 
            className={styles.select} 
            onChange={changeValueHandler(setUnitType)}
            value={unitType}
          >
            <option value="" disabled>Select unit type</option>
            {unitTypes.map((unitTypeData) => (
              <option key={unitTypeData.value} value={unitTypeData.value}>
                {unitTypeData.label}
              </option>
            ))}
          </select>
        </label>
        {unitType && unitType !== UNIT_TYPE_WITHOUT_AMOUNT && (
          <>
            <div className={styles.optional}>Optional:</div>
            <div className={styles.nutrients}>
              <label className={styles.row}>
                <span>Kcal</span>
                <input 
                  className={styles.input} 
                  onChange={changeValueHandler(setCalories)}
                  type="number"
                  placeholder="Kcal"
                  value={calories}
                />
              </label>
              <label className={styles.row}>
                <span>Fat</span>
                <input 
                  className={styles.input} 
                  onChange={changeValueHandler(setFat)}
                  type="number"
                  placeholder="Fat"
                  value={fat}
                />
              </label>
              <label className={styles.row}>
                <span>Protein</span>
                <input 
                  className={styles.input} 
                  onChange={changeValueHandler(setProtein)}
                  type="number"
                  placeholder="Protein"
                  value={protein}
                />
              </label>
              <label className={styles.row}>
                <span>Carbohydrates</span>
                <input 
                  className={styles.input} 
                  onChange={changeValueHandler(setCarbohydrates)}
                  type="number"
                  placeholder="Carbohydrates"
                  value={carbohydrates}
                />
              </label>
            </div>
            <button 
              className="button blue-button" 
              onClick={calculateNutrientsHandler}
              disabled={!name} 
            >
              Auto Calculate Nutrients
            </button>
          </>
        )}
        <div className={styles.alternative}>Alternative ingredients</div>
        <AlternativesSelect
          initialIngredients={initialValue?.alternatives} 
          onChange={setSelectedAlternativeList}
          ingredientsData={alternativeList}
        />
      </div>
    </ModalWrapper>
  );
};

const mapStateToProps = (store) => ({
  ingredientsData: store.ingredient.ingredientsData,
  ingredientNutrients: store.ingredient.ingredientNutrients,
});

const mapDispatchToProps = {
  addIngredient,
  updateIngredient,
  calculateNutrients,
};

export default connect(mapStateToProps, mapDispatchToProps)(IngredientModal);