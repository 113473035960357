import React, { useState, useEffect } from 'react';
import cn from 'classnames';

// import { arrayToMap } from '../../utils/functions';
import IngredientsSelect from '../IngredientsSelect/IngredientsSelect';
import styles from './DishForm.module.css';

const DishComponent = (props) => {
  const { 
    initialValues = new Map(), 
    onChange,
    ingredientsData,
  } = props;

  const [name, setName] = useState(initialValues?.name || '');
  const [title, setTitle] = useState(initialValues?.title || '');
  const [ingredients, setIngredients] = useState(initialValues?.ingredients || []);
  const [instruction, setInstruction] = useState(initialValues?.instruction || '');

  useEffect(() => {
    const componentData = {
      title,
      name,
      ingredients,
      instruction,
    };

    onChange(componentData);
  }, [name, title, ingredients, instruction]);

  return (
    <div>
      <label className={styles.row}>
        <span>Title of instruction</span>
        <input 
          className={styles.input} 
          placeholder="Title of instruction"
          value={name}
          onChange={({ target }) => setName(target.value)}
        />
      </label>
      <label className={styles.row}>
        <span>Instruction</span>
        <textarea 
          className={cn(styles.input, styles.textarea)}
          placeholder="Instruction"
          value={instruction}
          onChange={({ target }) => setInstruction(target.value)}
        />
      </label>
      <label className={styles.row}>
        <span>Title of ingredients</span>
        <input 
          className={styles.input} 
          placeholder="Title of ingredients"
          value={title}
          onChange={({ target }) => setTitle(target.value)}
        />
      </label>
      <div className={styles.dishComponents}>
        Ingredients
      </div>
      <div className={styles.ingredientsBlock}>
        <IngredientsSelect
          initialIngredients={ingredients}
          ingredientsData={ingredientsData} 
          onChange={setIngredients}
        />
      </div>
    </div>
  );
};

export default DishComponent;
