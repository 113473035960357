import {
  SET_ALLERGIES,
  DELETE_ALLERGY,
} from '../actions/allergy';

let initialState = {
  allergiesData: [],
};

const allergy = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALLERGIES:
      return {
        ...state,
        allergiesData: action.payload,
      };

    case DELETE_ALLERGY: {
      const { allergiesData } = state;
      const newAllergiesData = allergiesData.filter((allergyData) => {
        return allergyData.id !== action.payload;
      });

      return {
        ...state,
        allergiesData: newAllergiesData,
      };
    }

    default:
      return state;
  }
};

export default allergy;
