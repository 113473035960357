import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { getDishPriorities } from '../../actions/dish';
import MealPriorities from './MealPriorities';

const DishPriorities = (props) => {
  const { getDishPriorities, dishPriorities } = props;

  console.log('===dishPriorities', dishPriorities);

  useEffect(() => {
    getDishPriorities();
  }, []);

  return (
    <div className="wrapper">
      <h1>
        Dish Priorities
      </h1>
      {_.map(dishPriorities, (mealDishes, meal) => (
        <MealPriorities 
          key={meal} 
          mealDishes={mealDishes} 
          meal={meal} 
        />
      ))}
    </div>
  );
};

const mapStateToProps = (store) => ({
  dishPriorities: store.dish.dishPriorities,
});

const mapDispatchToProps = {
  getDishPriorities,
};

export default connect(mapStateToProps, mapDispatchToProps)(DishPriorities);