import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { getChatGTPTest } from '../../actions/mobileUser';

import styles from './ChatGPTTests.module.css';

const ChatGPTTests = (props) => {
  const { getChatGTPTest, chatGPTTests } = props;

  useEffect(() => {
    getChatGTPTest();
  }, []);

  return (
    <div className="wrapper">
      <h1>
        Mobile Users
      </h1>
      <table className="table">
        <thead>
        <tr>
          <th>Id</th>
          <th>Type</th>
          <th>Created at</th>
        </tr>
        </thead>
        <tbody>
        {chatGPTTests?.map((testData) => (
          <tr key={testData.id}>
            <td>{testData.id}</td>
            <td>
              <Link className={styles.link} to={`/chat-gpt-test/${testData.id}`} target="_blank">
                {testData.type}
              </Link>
            </td>
            <td>{moment(testData.created_at).format('DD/MM/yyyy HH:mm')}</td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (store) => ({
  chatGPTTests: store.mobileUser.chatGPTTests,
});

const mapDispatchToProps = {
  getChatGTPTest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatGPTTests);