import { get, put, getById, postFile, putFile, deleteById } from './api';

export const dishAPI = {
  getDishes() {
    return get('admin/dish');
  },

  getDishById(id) {
    return getById('admin/dish', id);
  },

  addDish(dishData) {
    console.log('===SEND ADD DISH', dishData);
    return postFile('admin/dish', dishData);
  },

  updateDish(ingredientData) {
    return putFile('admin/dish', ingredientData);
  },

  deleteDish(id) {
    return deleteById('admin/dish', id);
  },

  getPriorities(id) {
    return get('admin/dish-priorities', id);
  },

  setPriorities(data) {
    return put('admin/dish-priorities', data);
  },
};
