import React, { useState, useRef } from 'react';
import cn from 'classnames';

import styles from './VideoInput.module.css';

const VideoInput = (props) => {
  const { 
    name, 
    value, 
    onChange, 
    label,
  } = props;

  const id = `video-${name}-preview`;
  const fileInputRef = useRef(null);

  const [selectedVideo, setSelectedVideo] = useState(value);

  const deleteVideoHandler = () => {
    fileInputRef.current.value = null;
    setSelectedVideo(null);
    onChange('');
  };

  const changeHandler = (event) => {
    // fileInputRef.current.value = null;
    // props.input.onChange(e.target.files);
    if (event.target.files.length) {
      console.log('===event.target.files[0]', event.target.files[0]);
      setSelectedVideo(event.target.files[0]);
      onChange(event.target.files[0]);
    }
  };

  console.log('===selectedVideo', selectedVideo);

  return (
    <div>
      <div className={cn(!!selectedVideo && styles.hidden)}>
        <label className={styles.videoButton} htmlFor={id}>{label}</label>
        <input 
          className={styles.hidden} 
          id={id} 
          name={name}
          type="file"
          accept=".mp4"
          onChange={changeHandler} 
          ref={fileInputRef}
        />
      </div>
      {selectedVideo && (
        <div className={styles.videoWrapper}>
          <div 
            className={styles.closeVideo}
            onClick={deleteVideoHandler} 
          />
        </div>
      )}
    </div>
  );
};

export default VideoInput;
