import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { countries } from '../../constants/labels';
import { getMobileUsers } from '../../actions/mobileUser';

import styles from './MobileChatGPTUsers.module.css';

const MobileChatGPTUsers = (props) => {
  const { getMobileUsers, mobileUsersData } = props;

  useEffect(() => {
    getMobileUsers();
  }, []);

  return (
    <div className="wrapper">
      <h1>
        Mobile Users
      </h1>
      <table className="table">
        <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Country</th>
          <th>Created at</th>
        </tr>
        </thead>
        <tbody>
        {mobileUsersData?.map((userData) => (
          <tr key={userData.id}>
            <td>
              <Link className={styles.link} to={`/mobile-chat-gpt/${userData.id}`} target="_blank">
                {userData.name}
              </Link>
            </td>
            <td>{userData.email}</td>
            <td>{countries[userData.country]}</td>
            <td>{moment(userData.created_at).format('DD/MM/yyyy HH:mm')}</td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (store) => ({
  mobileUsersData: store.mobileUser.mobileUsersData,
});

const mapDispatchToProps = {
  getMobileUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileChatGPTUsers);