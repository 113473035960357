import React from 'react';
import Modal from 'react-modal';

import styles from './ModalWrapper.module.css';

const ModalWrapper = (props) => {
  const { children, buttonsRender, isOpen, onClose, onSave, title } = props;

  return (
    <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        className={styles.modal}
        overlayClassName={styles.overlay}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
      >
        <div>
          <div className={styles.header}>
            {title}
            <div className={styles.close} onClick={onClose}>
              ×
            </div>
          </div>
          {children}
          <div className={styles.body}>
            {buttonsRender?.()}
            <button className={styles.saveButton} onClick={onSave}>
              Save
            </button>
            <button className={styles.closeButton} onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </Modal>
  );
};

export default ModalWrapper;
