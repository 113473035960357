import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { weightUnitLabels } from '../../constants/labels';
import { getQuestionerDetails } from '../../actions/questioner';
import { countries, physicalActivityLabel, goalLabel} from '../../constants/labels';
import styles from './QuestionerDetails.module.css';

const QuestionerDetails = (props) => {
  const {
    getQuestionerDetails,
    questionerDetails,
  } = props;

  const { id } = useParams();
  const iframeRef = useRef(null);
  // const totalNutrients = {};

  useEffect(() => {
    getQuestionerDetails(id);
  }, []);

  const handleIframeLoad = () => {
    if (iframeRef.current) {
      const contentHeight = iframeRef.current.contentWindow.document.body.scrollHeight;
      iframeRef.current.style.height = `${contentHeight}px`;
    }
  };

  if (!questionerDetails) {
    return false;
  }

  return (
    <div className="wrapper">
      <h1>
        Questioner details
      </h1>
      <div className={styles.questionerDetails}>
        <div className={styles.leftPart}>
          <div className={styles.infoRow}>
            Name: {questionerDetails.name}
          </div>
          <div className={styles.infoRow}>
            Email: {questionerDetails.email}
          </div>
          <div className={styles.infoRow}>
            Country: {countries[questionerDetails.country]}
          </div>
          <div className={styles.infoRow}>
            Gender: {questionerDetails.gender === 'man' ? 'Male' : 'Female'}
          </div>
          <div className={styles.infoRow}>
            Date of birth: {moment(questionerDetails.birth_date).format('DD/MM/YYYY')}
          </div>
          <div className={styles.infoRow}>
            Weight: {questionerDetails.weight} {weightUnitLabels[questionerDetails.weight_unit]}
          </div>
          <div className={styles.infoRow}>
            Height: {questionerDetails.height} {questionerDetails.height_unit === 'cm' ? 'cm' : 'feet'}
          </div>
          <div className={styles.infoRow}>
            Physical activity: {physicalActivityLabel[questionerDetails.physical_activity]}
          </div>
          <div className={styles.infoRow}>
            Exclude meals: {questionerDetails.exclude_meals || '-'}
          </div>
          <div className={styles.infoRow}>
            Allergies: {questionerDetails.allergies || '-'}
          </div>
          <div className={styles.infoRow}>
            Goal: {goalLabel[questionerDetails.goal]}
          </div>
          <div className={styles.infoRow}>
            Calories: {questionerDetails.calories} kcal
          </div>
          <div className={styles.infoRow}>
            Protein: {questionerDetails.protein} grams
          </div>
          <div className={styles.infoRow}>
            Fat: {questionerDetails.fat} grams
          </div>
          <div className={styles.infoRow}>
            Carbohydrates: {questionerDetails.carbohydrates} grams
          </div>
          <div className={styles.infoRow}>
            Created at: {moment(questionerDetails.created_at).format('DD-MM-YYYY HH:mm')}
          </div>
        </div>
      </div>
      {!questionerDetails.menu && (
        <div className={styles.preline}>
          Processing..
        </div>
      )}
      <iframe
        ref={iframeRef}
        className={styles.menu}
        srcDoc={questionerDetails.menu}
        onLoad={handleIframeLoad}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  questionerDetails: state.questioner.questionerDetails,
});

const mapDispatchToProps = {
  getQuestionerDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionerDetails);
