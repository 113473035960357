const siteURL = 'https://mealwell.ai';
const backURL = 'https://api.mealwell.ai/';
const backURLApi = `${backURL}api/v1/`;

const config = {
  siteURL,
  backURL,
  backURLApi,
};

export default config;

