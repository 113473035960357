import React from 'react';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';

import styles from './DishPriorities.module.css';

export const SortableItem = ({ dish }) => {
  const { id, name } = dish;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef} 
      className={styles.dish} 
      style={style} 
      {...attributes} 
      {...listeners}
    >
      {name}
    </div>
  );
};
