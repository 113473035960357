import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import DishForm from '../../components/DishForm/DishForm';

import { addDish } from '../../actions/dish';

const AddDish = (props) => {
  const { addDish } = props;
  const navigate = useNavigate();

  const addDishHandler = (dishData) => {
    addDish(dishData, () => {
      navigate('/dishes');
    });
  };

  return (
    <div className="wrapper">
      <h1>
        Add Dish
      </h1>
      <DishForm onSave={addDishHandler} />
    </div>
  );
};

const mapDispatchToProps = {
  addDish,
};

export default connect(null, mapDispatchToProps)(AddDish);