import { settingsAPI } from '../api/settings';
import { actionWrapper } from './actionWrapper';

export const SET_SETTINGS = 'SET_SETTINGS';

export const getSettings = () => actionWrapper(async () => {
  const dishesData = await settingsAPI.getSettings();

  return {
    type: SET_SETTINGS,
    payload: dishesData,
  };
});

export const updateSettings = (data, callback) => actionWrapper(async (dispatch) => {
  await settingsAPI.updateSettings(data);
  dispatch(getSettings());
  callback?.();
});
