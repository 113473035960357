import { applyMiddleware, combineReducers, createStore } from 'redux';
import { thunk } from 'redux-thunk';

import auth from './auth';
import preloader from './preloader';
import questioner from './questioner';
import ingredient from './ingredient';
import allergy from './allergy';
import dish from './dish';
import mobileUser from './mobileUser';
import settings from './settings';

const USER_LOGOUT = 'USER_LOGOUT';

const allReducers = combineReducers({
  auth,
  preloader,
  questioner,
  ingredient,
  allergy,
  dish,
  mobileUser,
  settings,
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    state = undefined;
  }

  return allReducers(state, action);
};

let middleware = [thunk];

export default createStore(rootReducer, applyMiddleware(...middleware));
