import React from 'react';
import { connect } from 'react-redux';
import DishForm from '../../components/DishForm/DishForm';

import { getQuestioner } from '../../actions/questioner';
// import styles from './AddIngredient.module.css';

const AddDish = (props) => {
  console.log('===PROPS', props);
  // const { getQuestioner, questionerData } = props;

  return (
    <div className="wrapper">
      <h1>
        Add Dish
      </h1>
      <DishForm />
    </div>
  );
};

const mapStateToProps = (store) => ({
  questionerData: store.questioner.questionerData,
});

const mapDispatchToProps = {
  getQuestioner,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDish);