import React, { useState, useEffect } from 'react';
// import cn from 'classnames';

import ImageInput from '../ImageInput/ImageInput';
import styles from './DishForm.module.css';

const DishImage = (props) => {
  const { 
    initialValues, 
    onChange,
  } = props;

  const [image, setImage] = useState(initialValues || '');

  useEffect(() => {
    onChange(image);
  }, [image]);

  return (
    <div className={styles.dishImageBlock}>
      <ImageInput 
        value={image}
        onChange={setImage}
        name="image"
      />
    </div>
  );
};

export default DishImage;
