import React, { useRef } from 'react';
import styles from './ImportButton.module.css';

const ImportButton = (props) => {
  const { className, children } = props;
  const fileInputRef = useRef(null);

  const changeHandler = (event) => {
    const success = confirm('Are you shure?');
    if (success) {
      console.log('===SUBMIT');
    }
    fileInputRef.current.value = null;
    console.log('===SUCCESS', success);
    // props.input.onChange(e.target.files);
    // console.log('===event.target?.files', event.target?.files);
    let file = event.target?.files?.[0] || {};
    console.log('===FILE', file);
  };

  return (
    <div className={styles.wrapper}>
      <label className={className}>
        <input 
          ref={fileInputRef}
          className={styles.fileInput} 
          type="file" 
          accept=".csv"
          onChange={changeHandler}
        />
        {children}
      </label>
    </div>
  );
};

export default ImportButton;
