import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import cn from 'classnames';

import { 
  UNIT_TYPE_WITHOUT_AMOUNT,
  unitTypeLabels,
 } from '../../constants/unitTypes';
 import { getIngredientAmountString } from '../../utils/functions';
import IngredientDropDown from './IngredientsDropDown';
import styles from './IngredientsSelect.module.css';

const IngredientsSelect = (props) => {
  const { 
    initialIngredients, 
    ingredientsData,
    onChange,
   } = props;

  const [currentAlternative, setCurrentAlternative] = useState(null);
  const [selectedAlternativeList, setSelectedAlternativeList] = useState(new Map());
  const [amount, setAmount] = useState('');
  const [isRequired, setIsRequired] = useState(false);

  // console.log('===selectedAlternativeList', selectedAlternativeList);
  
  useEffect(() => {
    setCurrentAlternative(null);

    if (initialIngredients && ingredientsData?.length) {
      if (initialIngredients.length) {
        let selectedAlternativeList = new Map();
        initialIngredients.forEach(ingredient => {

          const ingredientId = ingredient?.id || ingredient;

          const ingredientData = ingredientsData.find(ingredient => ingredient.id == ingredientId);

          if (ingredient?.amount) {
            ingredientData.amount = ingredient?.amount;
          }

          if (ingredient.is_required) {
            ingredientData.is_required = ingredient.is_required;
          }
          
          selectedAlternativeList.set(+ingredientId, ingredientData);
        });
        setSelectedAlternativeList(selectedAlternativeList);
      } else {
        const newInitialIngredients = new Map(initialIngredients);
        setSelectedAlternativeList(newInitialIngredients);
      }
    } 
  }, [initialIngredients, ingredientsData]);

  const changeAlternativeHandler = (event) => {
    const { value } = event.target;
    const selectedIngredient = ingredientsData.find(ingredient => ingredient.id == value);
    setCurrentAlternative(selectedIngredient);
  };

  const changeAlternativeList = (alternativeList) => {
    setSelectedAlternativeList(alternativeList);
    onChange(alternativeList);
  };

  const addAlternativeHandler = () => {
    if (!currentAlternative) {
      return toast.error('Select ingredient', {
        position: 'bottom-right',
        duration: 3000,
      });
    }

    const hasAmount = currentAlternative.unit_type !== UNIT_TYPE_WITHOUT_AMOUNT;

    if (hasAmount && !amount) {
      return toast.error('Enter amount', {
        position: 'bottom-right',
        duration: 3000,
      });
    }

    const alternativeData = ingredientsData.find(ingredient => ingredient.id === currentAlternative.id);

    if (amount) {
      alternativeData.amount = amount;
    }

    alternativeData.is_required = hasAmount ? isRequired : false;

    selectedAlternativeList.set(currentAlternative.id, alternativeData);

    changeAlternativeList(new Map(selectedAlternativeList));
    setCurrentAlternative('');
    setAmount('');
    setIsRequired(false);
  };

  const deleteAlternativeHandler = (id) => {
    selectedAlternativeList.delete(id);
    changeAlternativeList(new Map(selectedAlternativeList));
  };

  const editAlternativeHandler = (id) => {
    const alternativeData = selectedAlternativeList.get(id);
    setCurrentAlternative(alternativeData);
    setAmount(alternativeData.amount);
    setIsRequired(+alternativeData.is_required);
  };

  const renderIngredientAmount = (ingredient) => {
    if (!ingredient.amount && ingredient.unit_type !== UNIT_TYPE_WITHOUT_AMOUNT) {
      return;
    }

    return getIngredientAmountString(ingredient, ingredient.amount, ingredient.is_required);
  };

  return (
    <>
      <div className={cn(styles.alternativeBlock, styles.withAmount)}>
        <IngredientDropDown 
          value={currentAlternative?.id || ''}
          onChange={changeAlternativeHandler}
          ingredientsData={ingredientsData}
        />
        
        <label className={styles.isRequired}>
          <span>Is required:</span>
          <input  
            checked={isRequired}
            onChange={({ target }) => setIsRequired(target.checked)}
            type="checkbox"
          />
        </label>

        {currentAlternative && currentAlternative.unit_type !== UNIT_TYPE_WITHOUT_AMOUNT && (
          <>
            <div className={styles.amount}>
              <span className={styles.unitType}>
                {unitTypeLabels[currentAlternative?.unit_type]}:
              </span>
              <input 
                type="number"
                className={styles.input} 
                placeholder="Enter amount"
                onChange={({ target }) => setAmount(target.value)}
                value={amount}
              />
            </div>
          </>
        )}

        <div className={styles.addAlternative} onClick={addAlternativeHandler}>
          +
        </div>
      </div>
      <div className={styles.alternativeList}>
        {Array.from(selectedAlternativeList.entries()).map(([id, ingredient], index) => (
          <div className={styles.selectedAlternative} key={`${id}.${index}`}>
            {renderIngredientAmount(ingredient)}
            <div className={styles.edit} onClick={() => editAlternativeHandler(id)}>
              🖋️ 
            </div>
            <div className={styles.delete} onClick={() => deleteAlternativeHandler(id)}>
              ×
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default IngredientsSelect;