import { get, put } from './api';

export const settingsAPI = {
  getSettings() {
    return get('admin/settings');
  },

  updateSettings(data) {
    return put('admin/settings', data);
  },
};
