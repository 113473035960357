import React from 'react';

import styles from './IngredientsSelect.module.css';

const IngredientsDropDown = (props) => {
  const {
    onChange,
    value,
    ingredientsData,
  } = props;

  return (
    <select 
      className={styles.select} 
      value={value}
      onChange={onChange}
    >
      <option value="" disabled>Select ingredient</option>
      {ingredientsData.map((ingredient) => (
        <option value={ingredient.id} key={ingredient.id}>
          {ingredient.name} ({ingredient.unit_type})
        </option>
      ))}
    </select>
  );
};

export default IngredientsDropDown;