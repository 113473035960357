import {
  SET_SETTINGS,
} from '../actions/settings';

let initialState = {
  settingsData: [],
};

const settings = (state = initialState, action) => {
  switch (action.type) {
    case SET_SETTINGS:
      return {
        ...state,
        settingsData: action.payload,
      };

    default:
      return state;
  }
};

export default settings;
