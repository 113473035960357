import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';

import { addAllergy, updateAllergy } from '../../actions/allergy';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import styles from './AllergiesModal.module.css';

const AllergiesModal = (props) => {
  const { 
    initialValue, 
    isOpen, 
    onClose,
    addAllergy,
    updateAllergy,
    allergiesData,
  } = props;

  const [name, setName] = useState('');  

  console.log('===initialValue', initialValue);

  useEffect(() => {
    setName(initialValue?.name);
  }, [isOpen, initialValue]);

  const title = initialValue ? 'Edit allergy' : 'Add new allergy';

  const changeValueHandler = setter => event => {
    setter(event.target.value);
  };

  const onSaveHandler = () => {
    const trimmedName = name.trim();
    if (!trimmedName) {
      return toast.error('Name is required', {
        position: 'bottom-right',
        duration: 3000,
      });
    }

    // Check if there is an allergy with the same name and unit type
    const existenceAllergy = allergiesData.find(allergy => {
      if (allergy.name !== trimmedName) {
        return false;
      }

      return initialValue?.id !== allergy.id;
    });

    if (existenceAllergy) {
      return toast.error('Allergy with this name already exists', {
        position: 'bottom-right',
        duration: 3000,
      }); 
    }

    const allergyData = {
      name: trimmedName,
    };

    if (initialValue) {
      allergyData.id = initialValue.id;
      updateAllergy(allergyData);
    } else {
      addAllergy(allergyData);
    }

    onClose();
  };

  return (
    <ModalWrapper 
      isOpen={isOpen} 
      onClose={onClose} 
      onSave={onSaveHandler} title={title}
    >
      <div className={styles.wrapper}>
        <label className={styles.row}>
          <span>Allergy name</span>
          <input 
            className={styles.input} 
            onChange={changeValueHandler(setName)}
            placeholder="Allergy name"
            value={name}
          />
        </label>
      </div>
    </ModalWrapper>
  );
};

const mapStateToProps = (store) => ({
  allergiesData: store.allergy.allergiesData,
});

const mapDispatchToProps = {
  addAllergy,
  updateAllergy,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllergiesModal);